import { AbsenceModel } from '@/models/app-user-dto'
import { LayoutService } from '@/services/layout-service'
import { UserprofileService } from '@/services/user-profile-service'
import { abseceTypes, periodicityTypes, weekDays } from '@/views/Patients/Dossier/Constants'
import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { ValidationObserver } from 'vee-validate'
import { Subscription } from 'rxjs'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { AdministrationHelper } from '../../AdministrationHelper'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class Absence extends Vue {
  private layoutService = LayoutService.getInstance()
  private absencesService = UserprofileService.getInstance()
  private userService = AuthService.getInstance()
  private subscription!: Subscription

  public absenceTypes = abseceTypes
  public periodicityTypes = periodicityTypes
  public joursHebdo = weekDays
  public userName = ''
  public isEdit = false
  public absence: AbsenceModel = {}
  public errorMessages: string[] = []
  public isSaving = false
  public userId!: string

  public async mounted () {
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    this.userId = this.$route.params.userId
    this.userName = (await this.userService.getUserById(this.userId)).fullName
    const absenceId = this.$route.params.absenceId
    this.subscription = this.absencesService.absenceObservationSelected$.subscribe(abs => {
      this.absence = { ...abs }
      if (!this.absence.absencetype && absenceId !== 'new') {
        const path = `/administration/absences/${this.userId}`
        this.$router.push(path)
      }
      this.isEdit = !!abs.id
    })
  }

  public get datetimeTitle () {
    if (this.absence.period === 1) {
      return this.absence.isAllDay ? 'Dates' : 'Dates et heures'
    }
    return 'Heures'
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    const isDateTimeValid = this.validateDateTime(observer)
    if (isValid && isDateTimeValid) {
      this.isSaving = true
      const results = await this.absencesService.AddAbsence(this.userId, this.absence)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (results) {
        this.goToAbsences()
      }
    } else if (!isValid) {
      let merged = this.$refs
      if (this.$refs.DateRangeUniqueRef) {
        merged = Object.assign(merged, (this.$refs.DateRangeUniqueRef as Vue).$refs)
      } else if (this.$refs.DateRangeNonUniqueRef) {
        merged = Object.assign(merged, (this.$refs.DateRangeNonUniqueRef as Vue).$refs)
      }
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  private validateDateTime (observer: any) {
    const timestart = new Date(`${this.absence.period !== 1
      ? '1990-01-01' : this.absence.dateRange?.from}T${this.absence.startHour ?? ''}`)
    const timeend = new Date(`${this.absence.period !== 1
      ? '1990-01-01' : this.absence.dateRange?.to}T${this.absence.endHour ?? ''}`)

    const datestart = new Date(this.absence.dateRange?.from ?? '')
    const dateend = new Date(this.absence.dateRange?.to ?? '')
    let dateValid = true
    if (datestart > dateend && observer.errors.endDate) {
      observer.errors.endDate.push('La date de fin ne peut être inférieure à la date de début')
      dateValid = false
    }
    if (timestart >= timeend && observer.errors.endHour) {
      observer.errors.endHour.push('L\'heure de fin ne peut être inférieure à l\'heure de début')
      dateValid = false
    }
    if (!!timestart.getMinutes() && timestart.getMinutes() % 5 !== 0 && observer.errors.startHour) {
      observer.errors.startHour.push('L\'heure de début devrait être par tranche de 5 min')
      dateValid = false
    }
    if (!!timeend.getMinutes() && timeend.getMinutes() % 5 !== 0 && observer.errors.endHour) {
      observer.errors.endHour.push('L\'heure de fin devrait être par tranche de 5 min')
      dateValid = false
    }
    return dateValid
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public async goToAbsences () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()

    this.errorMessages = []
    this.$router.go(-1)
  }

  public destroyed () {
    this.subscription.unsubscribe()
    this.absencesService.pushAbsence({ isAllDay: true, period: 1 })
  }
}
